import React from "react";
import "./Navbar.css";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MyAvatarImg from "../assets/static/images/Pic1.jpg";

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navLinks = [
    { label: "About", href: "#about" },
    { label: "Projects", href: "#projects" },
    { label: "Contact", href: "#contact" },
  ];

  const handleNavigation = (event, targetId) => {
    event.preventDefault(); // Prevent default anchor behavior
    if (window.navigateToSection) {
      window.navigateToSection(targetId.replace("#", ""));
    }
  };

  // Drawer for mobile navigation
  const drawer = (
    <Box
      sx={{ width: 150 }}
      role="presentation"
      onClick={handleDrawerToggle}
      onKeyDown={handleDrawerToggle}
    >
      <List>
        {navLinks.map((link) => (
          <ListItem key={link.label} disablePadding>
            <ListItemButton
              component="a"
              href={link.href}
              onClick={(e) => handleNavigation(e, link.href)}
            >
              <ListItemText primary={link.label} sx={{ textAlign: "center" }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar
      position="relative"
      sx={{ backgroundColor: "transparent", boxShadow: "none" }}
    >
      <Toolbar sx={{ position: "relative" }}>
        {/* Icon on the top-left */}
        <Avatar
          alt="MyAvatar"
          src={MyAvatarImg}
          sx={{ width: 40, height: 40, marginRight: 2, objectFit: "contain" }}
        />

        {/* Desktop Navigation */}
        <Box
          sx={{
            display: { xs: "none", md: "flex" }, // Show on desktop, hide on mobile
            alignItems: "center", // Vertically center items
            width: "100%", // Ensure the box spans the full navbar width
          }}
        >
          {/* Left-aligned navigation links */}
          {navLinks.map((link) => (
            <Button
              id="NavButtons"
              key={link.label}
              href={link.href}
              onClick={(e) => handleNavigation(e, link.href)}
              disableRipple
              disableElevation
              sx={{
                backgroundColor: "transparent",
                color: "white",
                textTransform: "none",
                padding: "6px 16px",
              }}
            >
              {link.label}
            </Button>
          ))}

          {/* Right-aligned "Contact Me" button */}
          <Button
            variant="outlined"
            href="#contact"
            disableRipple
            disableElevation
            onClick={(e) => handleNavigation(e, "#contact")}
            sx={{
              marginLeft: "auto", // Push to the right
              color: "#fff", // White text
              textTransform: "none",
              borderRadius: "7px", // Rounded button
              borderWidth: "2px",
              borderColor: "#fff",
              padding: "6px 16px",
              "&:hover": {
                backgroundColor: "#2636d8", // Slightly darker pink hover
                display: "inline-block",
                animation: "bounce 2s infinite", // Apply the bounce animation
              },
            }}
          >
            Contact Me!
          </Button>
        </Box>

        {/* Mobile Menu Button */}
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            justifyContent: "flex-end", // Push items to the end
            alignItems: "center", // Vertically center the icon
            width: "100%", // Ensure it spans the full width of the parent
          }}
        >
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              color: "white",
              display: { xs: "flex", md: "none" },
              marginLeft: "auto",
            }}
          >
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>

      {/* Drawer for Mobile Navigation */}
      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
