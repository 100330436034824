import { useEffect } from "react";

const ManualScroll = ({ sections }) => {
  useEffect(() => {
    const sectionElements = sections.map((id) => document.getElementById(id));
    let currentIndex = 0;
    let isNavigating = false; // Flag to temporarily disable scroll handling

    const handleScroll = (event) => {
      if (isNavigating) return; // Skip scroll handling if navigating through links

      if (event.deltaY > 0 && currentIndex < sections.length - 1) {
        currentIndex++;
      } else if (event.deltaY < 0 && currentIndex > 0) {
        currentIndex--;
      }

      sectionElements[currentIndex].scrollIntoView({ behavior: "smooth", block: "start" });
    };

    const handleLinkNavigation = (targetId) => {
      const targetIndex = sections.indexOf(targetId);
      if (targetIndex !== -1) {
        isNavigating = true;
        currentIndex = targetIndex;
        sectionElements[targetIndex].scrollIntoView({ behavior: "smooth", block: "start" });

        setTimeout(() => {
          isNavigating = false; // Re-enable scroll handling after smooth scroll
        }, 1000); // Adjust timeout to match the smooth scroll duration
      }
    };

    window.addEventListener("wheel", handleScroll);

    // Attach a custom global function to allow navigation links to trigger the scroll
    window.navigateToSection = handleLinkNavigation;

    return () => {
      window.removeEventListener("wheel", handleScroll);
      delete window.navigateToSection; // Clean up the global function
    };
  }, [sections]);

  return null;
};

export default ManualScroll;
