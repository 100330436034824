import * as THREE from 'three';

import React, { useEffect, useRef } from 'react';

import { createNoise3D } from 'simplex-noise'; // Import the 3D noise function

const Blob = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(450, 450);
    renderer.setPixelRatio(window.devicePixelRatio);

    const mount = mountRef.current;
    if (mount) {
      mount.appendChild(renderer.domElement);
    }

    // Geometry, material, and mesh
    const geometry = new THREE.SphereGeometry(5, 64, 64);
    const material = new THREE.MeshPhongMaterial({
      color: "#FFFF00", // Cyan color
      emissive: 0x000000,
      shininess: 250,
      specular: 0xffffff,
      transparent: true,
      opacity: 0.8,
    });
    const blob = new THREE.Mesh(geometry, material);
    scene.add(blob);

    // Lights
    const ambientLight = new THREE.AmbientLight(0xffffff, 100.6);
    scene.add(ambientLight);

    const pointLight = new THREE.PointLight(0xffffff, 5);
    pointLight.position.set(5, 5, 5);
    scene.add(pointLight);

    // Camera position
    camera.position.z = 3;

    // Create noise generator
    const noise3D = createNoise3D();
    let time = 0;

    function animateBlob() {
      const position = blob.geometry.attributes.position;
      const vertex = new THREE.Vector3();

      for (let i = 0; i < position.count; i++) {
        vertex.fromBufferAttribute(position, i);

        const noise = noise3D(vertex.x + time, vertex.y + time, vertex.z + time);
        vertex.normalize().multiplyScalar(1 + 0.20 * noise);

        position.setXYZ(i, vertex.x, vertex.y, vertex.z);
      }
      position.needsUpdate = true;
      time += 0.001;
    }

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      animateBlob();
      blob.rotation.y += 0.001;
      renderer.render(scene, camera);
    };

    animate();

    // Cleanup function
    return () => {
      if (mount) {
        mount.removeChild(renderer.domElement);
        renderer.dispose();
      }
    };
  }, []);

  return <div
  ref={mountRef}
  style={{
    width: '100%', // Full width of parent container
    height: '100%', // Full height of parent container
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}
  />;
};

export default Blob;
