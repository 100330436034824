import './FlipCard.css'; // Add CSS styles here

import * as THREE from 'three';
import React, { useEffect, useRef } from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const FlipCard = ({ modelPath, modelPos, modelScale, title, description }) => {
  const modelRef = useRef(null);

  useEffect(() => {
    const mountNode = modelRef.current;

    // Set up Three.js scene
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
    camera.position.z = 2;
    camera.position.y = 1;

    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(200, 300); // Match this to CSS width/height
    mountNode.appendChild(renderer.domElement);

    // Initialize controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.update();

    // Add lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 2);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 2);
    directionalLight.position.set(5, 5, 5);
    scene.add(directionalLight);

    // Add grid and axes helpers for debugging
    /* const gridHelper = new THREE.GridHelper(10, 10);
    scene.add(gridHelper);

    const axesHelper = new THREE.AxesHelper(2);
    scene.add(axesHelper); */

    // Load the model
    const loader = new GLTFLoader();
    loader.load(
      modelPath, // Path to the 3D model
      (gltf) => {
        const model = gltf.scene;
        scene.add(model);

        // Set model position, rotation, and scale
        model.position.set(modelPos.x, modelPos.y, modelPos.z);
        model.rotation.y = Math.PI / 4; // Rotate slightly
        model.scale.set(modelScale.x,modelScale.y,modelScale.z); // Scale the model

        console.log('Model position:', model.position);
        console.log('Model scale:', model.scale);

        // Add animation
        const animate = () => {
          requestAnimationFrame(animate);
          model.rotation.y += 0.005; // Rotate model
          renderer.render(scene, camera);
        };
        animate();
      },
      undefined,
      (error) => console.error('An error occurred loading the model:', error)
    );

    // Clean up on unmount
    return () => {
      mountNode.removeChild(renderer.domElement);
    };
  }, [modelPath, modelPos, modelScale]);

  return (
    <div className="flip-card">
      <div className="flip-card-inner">
        {/* Front Side */}
        <div className="flip-card-front">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>

        {/* Back Side */}
        <div className="flip-card-back">
          <div ref={modelRef}></div>
        </div>
      </div>
    </div>
  );
};

export default FlipCard;
